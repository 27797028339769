<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="搜索条件">
        <el-input
          size="mini"
          v-model="formInline.user"
          placeholder="ID/昵称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
        <el-button
          size="mini"
          type="success"
          icon="el-icon-edit"
          @click="allopen"
          >批量审核</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'left'
      }"
      :cell-style="{ textAlign: 'left' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="userNo" label="ID"> </el-table-column>
      <el-table-column prop="date" label="昵称 I 手机">
        <template slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.avatar"
            :preview-src-list="[row.avatar]"
          >
          </el-image>
          <div>
            <div>昵称：{{ row.nickname }}</div>
            <div>手机：{{ row.phone }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="所属门店">
        <template>{{ name }}</template>
      </el-table-column>
      <el-table-column prop="createTime" label="加入时间"> </el-table-column>
      <el-table-column prop="address" label="状态">
        <template slot-scope="{ row }">
          {{ state[row.userStatus] }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="open(row)"
            >审核通过</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserPage, reviewUser, batchReviewUser } from '../../api/user'
export default {
  data() {
    return {
      idList: [],
      state: ['待审核', '正常', '禁用'],
      name: localStorage.getItem('nickname'),
      tableData: [],
      total: 0,
      size: 20,
      current: 1,
      formInline: {
        user: '',
        region: ''
      }
    }
  },
  created() {
    this.getUserPage()
  },
  methods: {
    handleSelectionChange(val) {
      this.idList = val.map((res) => {
        return res.id
      })
      console.log(this.idList)
    },
    search() {
      this.current = 1
      this.getUserPage()
    },
    allopen() {
      if (!this.idList.length) {
        return this.$message({
          type: 'warning',
          message: '请选择用户!'
        })
      }
      this.$confirm('是否确定批量审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = await batchReviewUser({
            idList: this.idList,
            userStatus: 1
          })
          console.log(data)
          this.getUserPage()
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.idList = []
        })
        .catch(() => {})
    },
    open(row) {
      this.$confirm('是否确定审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data = await reviewUser({
            id: row.id,
            userStatus: 1
          })
          console.log(data)
          this.getUserPage()
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
        .catch(() => {})
    },
    async getUserPage() {
      const { data } = await getUserPage({
        pageSize: this.size,
        pageNum: this.current,
        searchKey: this.formInline.user,
        userStatus: 0
      })
      console.log(data)
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getUserPage()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getUserPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
